<template>
  <v-container>
    <DetallesSolicitud :cartLoadable="cart">
      <!-- se compara con false, ya que se el valor debe ser retornado por API, la cual retorna bool y no undefined/null -->
      <template v-slot:actions>
        <v-tooltip 
          v-if="!cart.isLoading" 
          v-for="(item, index ) in mapButtons(cart.data.solicitud)" 
          :key="index" 
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                class="primary"
                @click="item.action"
                :loading="operation.isLoading"
            >
              {{ item.text }}
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </template>
    </DetallesSolicitud>
    <v-dialog v-model="isFormModalOpen" max-width="600px">
      <component
          v-if="activeStep"
          :is="`${activeStep.formulario}-form`"
          @on-save="openConfirm"
          @on-cancel="isFormModalOpen = false"
      />
    </v-dialog>
    <ConfirmationModalComponent
        :isOpen="confirmationModal.isOpen"
        :description="confirmationModal.description"
        :isLoading="operation.isLoading"
        @confirm="handleConfirmation"
        @cancel="clearConfirmation"
    />
  </v-container>
</template>
<script>
import {
  AdjuntoForm,
  CantidadAdjuntoForm,
  CantidadForm,
  ComentarioForm,
  FechaForm,
  ComentarioAdjuntoForm,
  FechaAdjuntoForm
} from '@/components/CMForms';
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { mapActions, mapMutations } from "vuex";
import DetallesSolicitud from '@/components/DetallesSolicitud.vue';
import { Observable } from '@/utils/observable.js';
import { removePropsFromObjectIfNil } from '@/utils/data';

const initialConfirmationModal = {
  isOpen: false,
  description: '',
  data: null,
};

export default {
  name: "CMDetalleSolicitudesConObservacion",
  components: {
    ConfirmationModalComponent,
    DetallesSolicitud,
    AdjuntoForm,
    CantidadAdjuntoForm,
    CantidadForm,
    ComentarioForm,
    FechaForm,
    ComentarioAdjuntoForm,
    FechaAdjuntoForm,
  },
  data: () => ({
    operation: createLoadable(null),
    buttons: createLoadable([]),
    requestId: null,
    showButtons: false,
    bitacora: [],
    activeStep: null,
    isFormModalOpen: false,
    confirmationModal: initialConfirmationModal,
    cart: createLoadable(null),
  }),
  computed: {
    errorWhenAlteringState() {
      return this.operation.error?.message;
    },
  },
  methods: {
    ...mapActions("catalogoEnLinea", ["getOpenRequest"]),
    ...mapMutations("catalogoEnLinea", ["setFeedbackModalConfig"]),
    async loadButtonActions() {
      if (!this.haveRole('ROLE_CM_SOLICITUDES_CON_OBSERVACIONES')) return;

      toggleLoadable(this.buttons);
      const { data } = await this.services.cmAprobacionSolicitudCompra.obtenerBotones(this.requestId);
      setLoadableResponse(this.buttons, data);
    },
    mapButtons(request) {
      if (!request) return [];

      const allowedButtons = ['BTN-ACTIVAR', 'BTN-CADUCAR', 'BTN-EDITAR'];

      return this.buttons.data.map((item) => ({
        text: item.accion_requerida,
        id: item.id,
        accion: item.accion_requerida,
        action: () => this.handleStep(item)
      })).filter((b) => allowedButtons.includes(b.id));
    },
    clearConfirmation() {
      this.confirmationModal = initialConfirmationModal;
    },
    openConfirm(data, msg) {
      this.isFormModalOpen = false;
      this.confirmationModal = {
        isOpen: true,
        description: msg ?? `¿Desea ${this.activeStep.accion_requerida} esta solicitud?`,
        data: removePropsFromObjectIfNil(data, ['comentario', 'adjunto']),
      };
    },
    handleStep(step) {
      this.activeStep = step;

      // Flujo para editar la solicitud
      // const { id } = step;
      // if (id === 'BTN-EDITAR') {
      //   this.$router.push(`/cm-solicitudes/editar/${this.requestId}`)
      //   return;
      // }
      // Flujo normal
      if (!step.formulario) return this.openConfirm(null);
      this.isFormModalOpen = true;
    },
    async handleConfirmation() {
      this.confirmationModal.isOpen = false;

      const { id } = this.activeStep;
      let action;
      switch (id) {
        case 'BTN-ACTIVAR':
          action = this.services.CMSolicitudesConObservacion.markRequestAsActive;
          break;
        case 'BTN-CADUCAR':
          action = this.services.PurchaseRequest.markRequestAsClosed;
      }

      toggleLoadable(this.operation);
      const { data } = await action(this.requestId);
      setLoadableResponse(this.operation, data);
      this.isConfirmationOpen = false;

      if (!isResponseSuccesful(data)) {
        return;
      } else {
        this.loadButtonActions();
      }

      Observable.emit('refresh-requests-to-modify')
      this.getOpenRequest();
      this.setFeedbackModalConfig({
        isOpen: true,
        type: "success",
        description: data.message,
        buttonText: "Aceptar",
        onClose: () => this.$router.push(`/cm-solicitudes-con-observacion/${this.requestId}`),
      });
    },
    async loadCart() {
      toggleLoadable(this.cart);
      const { data } = await this.services.cmAprobacionSolicitudCompra.detalle(this.requestId);

      setLoadableResponse(this.cart, data);
    },
  },
  watch: {
    errorWhenAlteringState(value) {
      if (value) this.loadButtonActions();
    },
  },
  created() {
    this.requestId = this.$route.params.id;
    this.loadCart();
    this.loadButtonActions();
  },
}
</script>
